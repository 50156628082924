.card{  padding: 0.8rem;   background: #fff;   box-shadow: 2px 3px 2px 0 rgba(0,0,0,0.02), 2px 3px 1px -2px rgba(0,0,0,0.1), 2px 3px 5px 0 rgba(0,0,0,0.02);  transition: all .3s cubic-bezier(0.4,0,0.2,1);    position: relative;    font-family: var(--poppins);    display: flex;    flex-flow: wrap;    justify-content: space-between;}

.ca-i {    padding: 0;    width: 100%;    margin: 0;}

.ca-c {    margin-bottom: 1.6rem;}

.ca-p{font-size: var(--f-s-4); font-weight: var(--f-w-6);display: inline-block}

.ca-p ~ i {font-style: normal; margin-top: 0.5rem;} 

.ca-p ~ i:last-of-type {    border-top: 1px solid #444; }

.ca-m {    position: absolute;    right: 25px;    top: 25px;}

.ca-f-b-1 { min-width: 46px; min-height: 46px; line-height: 46px; background: var(--c-3); text-shadow: none; color: var(--t-c); display: block;text-align: center; margin-bottom: .2rem;}

.ca-f-b-1::before{margin-right: 0;display: block !important;width: 46px;height: 46px;line-height: 46px !important;margin: 0 !important;}

.ca-a-b-1 { text-decoration: none; padding: .6rem 1rem;  border: 1px solid #444;  display: inline-flex;  border-radius: 6px; font-size: var(--f-s-1);  background: transparent;  transition: .2s;  color: var(--t-c);}

.ca-a-b-1:hover{background: #444; color: #fff;}

.ca-a-b-1:last-of-type{float: right;}

.ca-l{color:rgba(255, 255, 255, .01); bottom:0; left:0;}

.card .mls-logo{width:50px;}
.p-badge .l-type{border-left:2px solid var(--c-1);    margin: 0 0 2px 0; }


.ca-ol::after{pointer-events:none; content:" ";background-color: transparent;background-image: linear-gradient(180deg, #00000000 40%, #000000E6 100%);opacity: 0.8; position: absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: 2;transition: 0.35s;}
.ca-ol::before{pointer-events:none;border: 1px solid rgba(255, 255, 255, .5);position: absolute;top: 10px;right: 10px;bottom: 10px;left: 10px;content: "";transition: opacity 0.35s, transform 0.35s; z-index:3}

x.ca-sl img {transition: 0.35s ease-in-out;}
x.ca-sl:hover img {-webkit-transform: scale(1.07); transform: scale(1.07);}


.ca-sl img {transition: .4s cubic-bezier(0.37,0,0.63,1)!important;transform: translate3d(0%, 0%, 0)!important}
.ca-sl:hover img {transform: translate3d(0%, 0%, 0) scale(1.1)!important}

a.ca-l img{display:none}
.card footer [data-agora-icon]:hover:before{color:var(--c-1);}
.card.property .full{right:0;color:var(--c-6);}
.card.property .full .ca-f-b-1{background:var(--c-5);opacity:.5;}




.c-article-card:hover .c-article-card__content-date .svg-arrow-right {
        transform: translate3d(-10px, 0, 0);
    }

.c-article-card__content-date .svg-arrow-right {
    width: 14px;
    transition: .4s var(--e-inOut-sin);
}


